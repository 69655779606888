<template>
  <div>
    <h1>Password Reset</h1>

    <b-alert
      v-if="formSuccess"
      variant="success"
      show
    >
      <p>
        We've emailed you instructions for setting your password. You should be
        receiving them shortly.
      </p>

      <p>
        If you don't receive an email, please make sure you've entered the
        correct email address, and check your spam folder.
      </p>
    </b-alert>
    <template v-else>
      <p class="text-center">
        Lost your password? Enter your email address below, and we'll email
        instructions for setting a new one.
      </p>

      <b-alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        show
      >
        {{ formErrors.non_field_errors[0] }}
      </b-alert>

      <b-form @submit.prevent="submitForm">
        <b-form-group
          label="Email address"
          label-for="email"
          :label-sr-only="true"
        >
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="Email address"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Resetting Password
            <spinner />
          </template>
          <template v-else>
            Reset Password
          </template>
        </b-button>

        <b-button
          type="button"
          variant="secondary"
          :disabled="formSubmitting"
          @click="goBack('login')"
        >
          Cancel
        </b-button>
      </b-form>
    </template>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'PasswordReset',
  components: {
    Spinner,
  },
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
      formSuccess: false,
      email: '',
    };
  },
  metaInfo: {
    title: 'Password Reset',
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const postData = {
        email: this.email,
      };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/password_reset/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [200, 400].includes(response.status)) {
        if (response.status === 200) {
          this.formSuccess = true;
        } else {
          const responseJson = await response.json();
          this.formErrors = responseJson;
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>
